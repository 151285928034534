import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";

const testimonials = [
  {
    avatar: "/assets/avatar1.png",
    name: "Sumit Kumar",
    rating: 5,
    description:
      "The team at MDMultiServices went above and beyond. Their attention to detail and commitment to customer satisfaction is truly impressive. I can't recommend them enough!",
  },
  {
    avatar: "/assets/avatar2.png",
    name: "Vicky",
    rating: 4,
    description:
      "I was very impressed with the level of professionalism shown by MDMultiServices. They handled everything efficiently and made the entire process seamless.",
  },
  {
    avatar: "/assets/avatar3.png",
    name: "Sourav Saike",
    rating: 5,
    description:
      "Absolutely fantastic experience! The service was prompt, and the staff were incredibly friendly. I will definitely be using their services again in the future.",
  },
  {
    avatar: "/assets/avatar4.png",
    name: "Faride Barghi",
    rating: 5,
    description:
      "MDMultiServices has exceeded my expectations! They provided me with excellent support, and the results were outstanding. Highly recommend them to anyone in need of quality services.",
  },
  {
    avatar: "/assets/avatar5.png",
    name: "Rahul",
    rating: 4,
    description:
      "Reliable and trustworthy service. I appreciated how they communicated with me throughout the process, keeping me informed every step of the way.",
  },
];

const TestimonialsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isHovered) return; // Stop auto-scroll when hovered

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(interval);
  }, [isHovered]);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="mt-10">
      <h2 className="text-center text-4xl md:text-5xl font-bold ">
        What Our <span className="text-green-400">Clients Say</span>
      </h2>
      <div
        className="w-full mx-auto p-4 m-8"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="relative flex items-center justify-center w-full">
          <button
            onClick={prevTestimonial}
            className=" hover:text-gray-700 absolute left-0 z-10 border-red-500 bg-[--highlight-color] w-12 h-12 rounded-full text-xl"
          >
            &lt;
          </button>

          <div className="overflow-hidden w-[85%] ">
            <div
              className="flex transition-transform duration-500"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {testimonials.map(
                ({ avatar, name, rating, description }, index) => (
                  <div key={index} className="px-[1px] md:px-10  flex-shrink-0 w-full">
                    <div className=" rounded-lg shadow-lg text-center p-8 glass_effect">
                      <img
                        src={avatar}
                        alt={name}
                        className="w-24 h-24 rounded-full mx-auto mb-4 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]"
                      />
                      <h3 className="text-xl font-semibold mb-3 text-white">
                        {name}
                      </h3>
                      <div className="flex justify-center mb-4">
                        {[...Array(5)].map((_, i) => (
                          <FaStar
                            key={i}
                            className={`h-5 w-5 my-1 ${
                              i < rating ? "text-yellow-500" : "text-gray-300"
                            }`}
                          />
                        ))}
                      </div>
                      <p className="text-white font-normal text-sm max-w-2xl m-auto leading-6">{description}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>

          <button
            onClick={nextTestimonial}
            className="  hover:text-gray-700  border-red-500 bg-[--highlight-color] w-12 h-12 rounded-full text-xl absolute right-0 z-10"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
