import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Loader from "../../component/Loader";

const ApplyPage = () => {
  const { REACT_APP_API_URL } = process.env;
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data, "data");

    data.location = job.location;
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${REACT_APP_API_URL}/applyJob/${jobId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          Swal.fire({
            title: "Success!",
            text: "Your application has been submitted successfully!",
            icon: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/listJob/${jobId}`
        );
        setJob(response.data); // Access the first element in the array
      } catch (err) {
        if (err.code === "ECONNABORTED") {
          setError("Failed to load job details. Please try again later.");
        } else if (err) {
          setError("Job not found");

          console.error(err + "error");
        } else {
          setError("Something went wrong. Please try again later.");
        }
      } finally {
        setLoader(false);
      }
    };

    fetchJobDetails();
    // eslint-disable-next-line
  }, [jobId]);
  if (loader) return <Loader />;

  return (
    <div className="flex flex-col justify-center items-center p-5 w-full m-auto relative overflow-hidden ">
      <img src="/assets/BGcircle.png" alt="" className="image1" />
      <img src="/assets/BGcircle.png" alt="" className="image2" />
      <img src="/assets/BGcircle.png" alt="" className="image3" />
      {job ? (
        <div className="flex flex-col justify-end align-middle my-5 pl-2 w-full">
          <h1 className="text-center text-md md:text-3xl lg:text-4xl mb-10 xl:mb-20">
            Apply for<span className="text-green-400"> {job.title}</span>
          </h1>
          <div className="flex flex-col w-full justify-center align-middel xl:flex-row max-w-[1200px] m-auto">
            <div className="leading-8 flex flex-col  w-full my-10 xl:my-0 lg:mr-12 lg:my-10 text-justify gap-6">
              <p>
                <span className="span_left">Job Title : </span>
                {job.title}
              </p>
              <p>
                <span className="span_left">Location : </span>
                {job.location}
              </p>
              <p>
                <span className="span_left">About This Job : </span>
                {job.description}
              </p>
              <p>
                <span className="span_left">Mode : </span>
                {job.mode}
              </p>
              <p>
                <span className="span_left"> Skills :</span>{" "}
                {job.skills.map((skill, index) => (
                  <span key={index} className="mr-2">
                    {skill.skill}
                    {index !== job.skills.length - 1 ? ", " : ""}
                  </span>
                ))}
              </p>
              <p>
                <span className="span_left">Experience : </span>
                {job.experience}
              </p>
              <p>
                <span className="span_left">Salary : </span>
                {job.salary}
              </p>
              <p>
                <span className="span_left">Posted on : </span>
                {job.postedOn}
              </p>
              <p>
                <span className="span_left">Apply before : </span>
                {job.applyBefore}
              </p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" p-8 rounded-md w-full max-w-3xl xl:min-w-[600px] m-auto glass_effect"
            >
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Full Name :</label>
                <input
                  type="text"
                  {...register("name", { required: "Name is required" })}
                  className="p-2 rounded-md glass_effect_inner text-white"
                  placeholder="Enter your name"
                />
                {errors.name && (
                  <span className="text-red-500">{errors.name.message}</span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Email :</label>
                <input
                  type="email"
                  {...register("email", { required: "Email is required" })}
                  className="p-2 rounded-md glass_effect_inner text-white"
                  placeholder="Enter your email"
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email.message}</span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Mobile :</label>
                <input
                  type="text"
                  {...register("number", {
                    required: "Mobile Number is required",
                  })}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  className="p-2 rounded-md glass_effect_inner text-white"
                  placeholder="Enter your Mobile Number"
                />
                {errors.number && (
                  <span className="text-red-500">{errors.number.message}</span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Experience :</label>
                <select
                  {...register("experience", {
                    required: "Experience is required",
                  })}
                  className="p-2 rounded-md glass_effect_inner text-white"
                >
                  <option className="bg-blue-950" value="">
                    Select experience in years
                  </option>
                  <option className="bg-blue-950" value="fresher">
                    Fresher
                  </option>
                  <option className="bg-blue-950" value="1 year">
                    less than 1 year
                  </option>
                  <option className="bg-blue-950" value="2 year">
                    2 years
                  </option>
                  <option className="bg-blue-950" value="3 year">
                    3 years
                  </option>
                  <option className="bg-blue-950" value="3 year">
                    3 years +
                  </option>
                </select>
                {errors.experience && (
                  <span className="text-red-500">
                    {errors.experience.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Linkedin :</label>
                <input
                  type="text"
                  {...register("linkedIn", {
                    required: "Linkedin is required",
                  })}
                  className="p-2 rounded-md glass_effect_inner text-white"
                  placeholder="Enter your Linkedin"
                />
                {errors.linkedIn && (
                  <span className="text-red-500">
                    {errors.linkedIn.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Portfolio :</label>
                <input
                  type="text"
                  {...register("portfolio", {
                    required: "Portfolio is required",
                  })}
                  className="p-2 rounded-md glass_effect_inner text-white"
                  placeholder="Enter your Portfolio"
                />
                {errors.portfolio && (
                  <span className="text-red-500">
                    {errors.portfolio.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-2">Attached Resume : </label>
                <div className="flex items-center border-dashed border-2 border-blue-800 p-4 rounded-md">
                  <input
                    type="file"
                    {...register("resume", { required: "Resume is required" })}
                    className="text-white "
                  />
                </div>
                {errors.resume && (
                  <span className="text-red-500">{errors.resume.message}</span>
                )}
              </div>
              <div className="flex flex-col mb-4">
                <label className="text-white mb-3">Cover Letter : </label>
                <textarea
                  {...register("message", { required: "Message is required" })}
                  className="p-2 rounded-md glass_effect_inner text-white"
                  placeholder="Write your message here"
                  rows="4"
                ></textarea>
                {errors.message && (
                  <span className="text-red-500">{errors.message.message}</span>
                )}
              </div>
              <div className="flex items-center space-x-2 my-4">
                <input
                  type="checkbox"
                  {...register("privacy", {
                    required: "Please accept the privacy policy",
                  })}
                  className="rounded-md bg-blue-800 text-white"
                />
                <label className="text-white">
                  I accept the{" "}
                  <Link
                    href="/privacy"
                    className="text-[#00ff00] hover:underline"
                  >
                    Privacy Policy
                  </Link>
                </label>
              </div>
              {/* Buttons */}
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => reset()}
                  className="bg-blue-800 w-full md:w-auto bg-[--highlight-color] text-white px-6 py-2 rounded-md transition duration-300 ease-in-out transform hover:bg-blue-900 hover:drop-shadow-lg hover:scale-105;"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="[--highlight-color] text-white px-6 py-2 rounded-md w-full md:w-auto btn-hover-effect"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="h-[85vh] flex justify-center items-center">
          {/* create a job not found  */}

          <h1 className="text-2xl text-center  ">
            <p className="text-center">404</p>
            Something Went Worng Try again Later
            <p>{error}</p>
          </h1>
        </div>
      )}
    </div>
  );
};

export default ApplyPage;
