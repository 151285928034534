export const cardData = [
  {
    title: "Software",
    highlight: "Development",
    img: "/assets/stack2.png",
    description:
      "We specialize in crafting high-quality software applications tailored to meet your business needs. From initial concept to final deployment, our software development services are designed to deliver robust, scalable, and maintainable solutions.",
  },
  {
    title: "Software",
    highlight: "Solution",
    img: "/assets/software.png",
    description:
      "Our custom software solutions are developed to address unique business challenges. We ensure that your software integrates seamlessly with your workflow, boosting productivity and improving operational efficiency.",
  },
  {
    title: "UI/",
    highlight: "UX",
    img: "/assets/pencil.png",
    description:
      "We design intuitive user interfaces and experiences that are not only visually appealing but also user-friendly. Our UX/UI services focus on improving user engagement and satisfaction across all digital touchpoints.",
  },

];
export const cardData2 = [

  {
    title: "DevOps",
    highlight: "Services",
    img: "/assets/cloud.png",
    description:
      "Our DevOps consulting services ensure that your development and operations teams collaborate effectively. We implement continuous integration and delivery pipelines, automate workflows, and ensure a smooth, efficient release process.",
  },
  {
    title: "SEO",
    highlight: "Services",
    img: "/assets/seo.png",
    description:
      "Boost your online visibility with our SEO services. We help your website rank higher on search engines, attract more traffic, and convert visitors into customers through effective on-page and off-page optimization strategies.",
  },
  {
    title: "Social Media",
    highlight: "Marketing",
    img: "/assets/marketing1.png",
    description:
      "Our social media marketing services help you connect with your audience on platforms like Facebook, Instagram, and LinkedIn. We create engaging content and campaigns that increase your brand awareness and drive conversions.",
  },
];
