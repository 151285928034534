import React from "react";
import "./PopUp.css";
import { Link } from "react-router-dom";

function PopUp({ title, highlight, img, description, onClose }) {
  return (
    <div className="popup_overlay" onClick={onClose}>
      <div className="popup_content" onClick={(e) => e.stopPropagation()}>
        <img
          src={img}
          alt={title}
          className="border-2 border-[--highlight-color] p-6 rounded-full"
        />
        <h1>
          {title} <span className="highlight">{highlight}</span>
        </h1>
        <p>
          {description}{" "}
          <Link to="/services" className="highlight underline">
            Learn More
          </Link>{" "}
        </p>
        <button className="close_button" onClick={onClose}>
          x
        </button>
      </div>
    </div>
  );
}

export default PopUp;
