import React, { useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useIsSsr } from "../Hooks/useIsSsr";

const Navbar = () => {
  const isSSr = useIsSsr(); // Hook to check if SSR is happening
  const [isOpen, setIsOpen] = useState(false); // State to manage mobile menu
  const [prevScrollPos, setPrevScrollPos] = useState(0); // Initialize to 0
  const [visible, setVisible] = useState(true);

  // Use useCallback to memoize the handleScroll function
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  // Effect to handle scroll event listener
  useEffect(() => {
    if (isSSr) return; // Prevent adding scroll event listener during SSR

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, isSSr]);

  // if (isSSr) return null;

  return (
    <nav
      className={`bg-[--bg-color] p-4 fixed top-0 left-0 w-full z-50 navbar ${
        !visible ? "navbar-hidden" : ""
      }`}
      id="header"
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-white text-2xl font-bold">
          <NavLink to="/">
            <img src="/assets/logo.png" alt="Logo" className="h-10 w-10" />
          </NavLink>
        </div>

        {/* Hamburger Icon (Mobile) */}
        <div className="lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Navbar Links (Desktop) */}
        <div className="hidden lg:flex space-x-8 text-white">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "text-green-400" : "hover:text-green-400"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive ? "text-green-400" : "hover:text-green-400"
            }
          >
            Services
          </NavLink>
          <NavLink
            to="/career"
            className={({ isActive }) =>
              isActive ? "text-green-400" : "hover:text-green-400"
            }
          >
            Career
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? "text-green-400" : "hover:text-green-400"
            }
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? "text-green-400" : "hover:text-green-400"
            }
          >
            About Us
          </NavLink>
        </div>

        {/* Mobile Menu Links */}
        {isOpen && (
          <div className="lg:hidden absolute top-16 left-0 w-full bg-[--bg-color] text-white">
            <ul className="flex flex-col items-center space-y-4 py-4">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "text-green-400" : "hover:text-green-400"
                  }
                  onClick={() => setIsOpen(false)}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className={({ isActive }) =>
                    isActive ? "text-green-400" : "hover:text-green-400"
                  }
                  onClick={() => setIsOpen(false)}
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/career"
                  className={({ isActive }) =>
                    isActive ? "text-green-400" : "hover:text-green-400"
                  }
                  onClick={() => setIsOpen(false)}
                >
                  Career
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive ? "text-green-400" : "hover:text-green-400"
                  }
                  onClick={() => setIsOpen(false)}
                >
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) =>
                    isActive ? "text-green-400" : "hover:text-green-400"
                  }
                  onClick={() => setIsOpen(false)}
                >
                  About Us
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
