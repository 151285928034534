import React from "react";
import "./Card.css";
import { motion } from "framer-motion";
import { fadein } from "../../varients";

function Card({ direct, cardData, handleCardClick }) {
  // console.log(cardData, handleCardClick);
  return (
    <div className="ourservices_content">
      <div className="card_container">
        {cardData?.map((card, index) => (
          <motion.div
          variants={fadein(direct, 0.2 + index * 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="card"
            key={index}
            onClick={() => handleCardClick(card)}
          >
            <div className="card_img">
              <img src={card.img} alt={card.title} />
            </div>
            <h3 className="card_title">
              {card.title} <span className="highlight">{card.highlight}</span>
            </h3>
            {/* <p>{card.description}</p> */}
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Card;