import React from "react";

const Loader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {/* Adding a spinner animation for better visibility */}
      <div className="relative">
        <img
          src="/assets/MDLogo.png"
          alt="MD Logo"
          className="w-20 h-20 rounded-full animate-spin"
        />
      </div>
    </div>
  );
};

export default Loader;
