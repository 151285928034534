import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { fadein } from "../varients";
import axios from "axios";
import { Link } from "react-router-dom";
import Loader from "./Loader";

const Career = () => {
  const { REACT_APP_API_URL } = process.env;
  const [jobList, setJobList] = useState([]);
  const [filterData, setFilterData] = useState({
    skill: "",
    location: "",
    mode: "",
  });
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true); // Start loader on fetch
    axios
      .get(`${REACT_APP_API_URL}/listJob`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setJobList(response.data);
        setFilteredJobs(response.data);
        setLoading(false); // End loader on success
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false); // End loader on error
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filteredJobs = jobList.filter((job) => {
      const skillMatch = filterData.skill
        ? job.skills.some((skillObj) =>
            skillObj.skill
              .toLowerCase()
              .includes(filterData.skill.toLowerCase())
          )
        : true;
      const locationMatch = filterData.location
        ? job.location.toLowerCase().includes(filterData.location.toLowerCase())
        : true;
      const modeMatch = filterData.mode
        ? job.mode.toLowerCase().includes(filterData.mode.toLowerCase())
        : true;

      return skillMatch && locationMatch && modeMatch;
    });

    setFilteredJobs(filteredJobs);
  }, [filterData, jobList]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="w-[100vw] relative overflow-hidden">
      <img src="/assets/BGcircle.png" alt="" className="image1" />
      <img src="/assets/BGcircle.png" alt="" className="image2" />
      <img src="/assets/BGcircle.png" alt="" className="image3" />
      <div className="flex flex-col items-center mb-10 py-10 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <motion.h1
          variants={fadein("up", 0.2)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.7 }}
          className="text-white font-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-8 text-center"
        >
          WE ARE <span className="text-green-400">HIRING</span> FOR
        </motion.h1>

        <motion.div
          variants={fadein("up", 0.2)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.7 }}
          className="flex flex-col sm:flex-row p-0 border-1 g-gradient-to-r from-blue-700 to-blue-800 rounded-xl mt-10 mb-16 space-y-4 sm:space-y-0 sm:space-x-4 w-full max-w-4xl"
        >
          <input
            type="text"
            name="skill"
            placeholder="Skill/Profile"
            className="p-2 rounded-md bg-blue-700 text-white w-full glass_effect"
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            className="p-2 rounded-md bg-blue-700 text-white w-full glass_effect"
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="mode"
            placeholder="Mode"
            className="p-2 rounded-md bg-blue-700 text-white w-full glass_effect"
            onChange={handleFilterChange}
          />
          <button className="bg-[--highlight-color] text-white px-6 py-2 rounded-md w-full sm:w-auto btn-hover-effect">
            Submit
          </button>
        </motion.div>

        {loading ? (
          <div>
            <Loader />
          </div>
        ) : error ? (
          <div className="text-white text-center h-[50vh]">
            Error: {error.message}
            <p>
              Please try again later or contact us at{" "}
              <a
                href="mailto:info.mdmultiservices.in"
                className="text-[#5fb92b] hover:underline"
              >
                info.mdmultiservices.in
              </a>
            </p>
          </div>
        ) : (
          <div className="flex flex-1 flex-wrap justify-center gap-10 max-w-[1400px]">
            {filteredJobs.map((job, index) => (
              <motion.div
                key={index}
                variants={fadein("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.7 }}
                className="glass_effect p-6 rounded-md text-center text-white w-full  mb-4 max-w-[600px] relative"
              >
                <h3 className="text-left text-xl font-bold text-[#5fb92b] mb-2">
                  {job.title}
                </h3>
                <div className="w-[100%] bg-gray-400 h-[1px] m-auto  mt-5 mb-1"></div>
                <div className="flex justify-between flex-col my-auto">
                  <div className="text-left mb-3 leading-10">
                    <p className="mt-2">
                      Location :
                      <span className="text-sm p-1 px-2 shadow-2xl border-[#24248d8a] border-[1px] bg-[#2a61c046] rounded-xl ml-5">
                        {job.location}
                      </span>
                    </p>
                    <p className="my1">
                      Mode :
                      <span className="text-sm p-1 px-2 shadow-2xl border-[#24248d8a] border-[1px] bg-[#2a61c046] rounded-xl ml-5">
                        {job.mode}
                      </span>
                    </p>
                    <div className="my-1 xl:flex flex-wrap gap-5 xl:gap-2">
                      <div>Skills :</div>
                      <div className="flex flex-wrap gap-2 max-w-[600px]">
                        {job.skills.map((skill, i) => (
                          <div
                            key={i}
                            className="text-sm leading-7 px-3 shadow-2xl bg-[#2a73c04f] rounded-xl mx-1"
                          >
                            {skill.skill}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Link
                    className="btn-hover-effect w-fit m-auto"
                    to={`/career/${job.jobId}`}
                  >
                    Apply Now
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Career;
