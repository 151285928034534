import React from "react";
import { motion } from "framer-motion";
import { fadein } from "../varients";

function Page() {
  const projects = [
    {
      id: 1,
      title: "Full-Stack Development",
      description:
        "From idea to execution, our comprehensive solutions transform your vision into a smooth, high-performance experience. We take care of everything from robust backend functionality to engaging frontend design, crafting applications that are both durable and centered around the user.",
      src: "/assets/FullstackIMG.jpg",
      heightClass: "h-60", // Set different height classes for masonry effect
    },
    {
      id: 2,
      title: "UI/UX Design",
      description:
        "Exceptional design goes beyond aesthetics; it creates an experience. We craft intuitive, visually appealing interfaces that not only attract attention but also ensure smooth navigation. Our designs engage users and encourage them to return...",
      src: "/assets/Uiux.jpg",
      heightClass: "h-80",
    },
    {
      id: 3,
      title: "DevOps Services",
      description:
        "Experience a smoother development process with our DevOps services, combining speed and stability. We offer efficient, agile workflows and cutting-edge CI/CD practices, creating an environment that evolves alongside your growth.",
      src: "/assets/Devops.jpg",
      heightClass: "h-48",
    },
    {
      id: 4,
      title: "SEO Optimization",
      description:
        "Make your brand shine and get noticed! With our SEO skills, we help you connect with the right audience, boosting organic traffic and establishing a strong online presence. Elevate your brand’s message and ensure it reaches the people who matter most.",
      src: "/assets/SEO.jpg",
      heightClass: "h-72",
    },
    {
      id: 5,
      title: "Social Media Marketing",
      description:
        "Your brand’s story is worth sharing. We develop engaging social media campaigns that foster loyalty, ignite interaction, and enhance your brand’s visibility on all platforms, leaving a lasting impression.",
      src: "/assets/SocialMedia.jpg",

      heightClass: "h-64",
    },
  ];

  const para = [
    "MDMultiservices is a reputable consulting company specializing in empowering businesses through top-tier Software Engineer services and holistic development solutions.",

    "We are a team of highly skilled and experienced professionals who are passionate about helping businesses grow and thrive in the digital world. We provide a wide range of software development services to help you harness the power of technology, consulting and  maximize your online investment.",

    "Our services include Software Development, Software Solution, UI/UX, DevOps Services, SEO Services, Social Media Marketing, and more. We are committed to providing high-quality services that will help you achieve your business goals and objectives.",

    "Our team of experts will work closely with you to understand your needs and develop a customized solution that meets your requirements. We have helped many businesses achieve success by providing them with the right tools and strategies they need to succeed in today's competitive market",

    "If you want to take your business to the next level, then contact us today!",
  ];

  return (
    <>
      <div className="w-[100vw] relative overflow-hidden">
        <img src="/assets/BGcircle.png" alt="" className="image1" />
        <img src="/assets/BGcircle.png" alt="" className="image2" />
        <img src="/assets/BGcircle.png" alt="" className="image3" />
        <div className="container mx-auto my-0 p-4 overflow-hidden">
          <div className="flex flex-col items-center justify-center min-h-screen relative">
            <motion.h1
              variants={fadein("down", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="text-white font-black text-3xl sm:text-3xl md:text-4xl md:mt-5 lg:text-5xl mb-10 text-center"
            >
              Our <span className="text-green-400">Services</span>
            </motion.h1>
            <motion.h1
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="text-white font-black    text-center"
            >
              <h5 className="mb-8 text-xl">
                <span className="text-green-400">Elevate Your Brand </span> with
                Our Expertise
              </h5>
              <p className=" m-auto max-w-3xl text-sm text-justify font-normal leading-6">
                <span className="text-3xl">W</span>e empower your brand to
                succeed in the digital world with a wide range of services aimed
                at fostering growth, improving user experiences, and building a
                robust online presence. Our offerings include everything from
                complete development and creative design to smooth deployments,
                focused SEO, and effective social media strategies. With our
                expertise, we turn ideas into high-performance, customer-focused
                solutions. Explore how our commitment to quality and innovation
                can help realize your vision.
              </p>
            </motion.h1>
            <div className="w-full sm:w-4/5 text-center">
              {projects.map((item) => {
                return (
                  <>
                    <motion.div
                      variants={fadein("up", 0.2)}
                      initial="hidden"
                      whileInView={"show"}
                      viewport={{ once: true, amount: 0.7 }}
                      key={item.id}
                      className="flex flex-col items-center p-5 md:flex-row md:text-left my-10 bg-gradient-to-r from-blue-800 to-blue-950 rounded-2xl max-w-3xl m-auto glass_effect"
                    >
                      <img
                        width={175}
                        className="rounded-2xl"
                        src={item.src}
                        alt=""
                      />
                      <div className="p-5">
                        <h1 className="font-medium text-3xl mb-5 ">
                          {item.title}
                        </h1>
                        <p className="text-slate-400">{item.description}</p>
                      </div>
                    </motion.div>
                  </>
                );
              })}
            </div>
            <div className="text-justify font-normal md:w-4/5 my-10">
              {para.map((item) => {
                return (
                  <motion.p
                    variants={fadein("up", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: true, amount: 0.7 }}
                    className="pb-5"
                  >
                    {item}
                  </motion.p>
                );
              })}
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"> */}
            {/* {projects.map((project) => (
            <div
              key={project.id}
              className={`service-card p-6 bg-white rounded-lg shadow-md ${project.heightClass}`}
            >
              <h2 className="text-2xl font-semibold mb-4">{project.title}</h2>
              <p className="text-gray-600 mb-4">{project.description}</p>
              <Link to={project.link} className="text-blue-500 hover:underline">
                Learn More
              </Link>
            </div>
          ))} */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
