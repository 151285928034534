import React, { useState } from "react";
import "./LandingPage.css";
import TypewriterComponent from "typewriter-effect";
import { Link } from "react-router-dom";
import Card from "./component/Cards/Card";
import { cardData, cardData2 } from "./constant/data";
import PopUp from "./component/PopUp/PopUp";
import { motion } from "framer-motion";
import { fadein } from "./varients";
import TestimonialsCarousel from "./component/TestimonialsCarousel";

function LandingPage() {
  const [selectedCard, setSelectedCard] = useState(null); // Initialize with null

  const handleCardClick = (card) => {
    console.log(card, "selectedCard"); // Log the selected card
    setSelectedCard(card); // Set the selected card when clicked
  };

  const closePopup = () => {
    setSelectedCard(null); // Close the popup
  };

  return (
    <>
      <div className="w-[100vw] relative overflow-hidden">
        <img src="/assets/BGcircle.png" alt="" className="image1" />
        <img src="/assets/BGcircle.png" alt="" className="image2" />
        <img src="/assets/BGcircle.png" alt="" className="image3" />
        {/* <img src="/assets/BGcircle.png" alt="" className="image4" /> */}
        <div className="container_hero">
          {/* <img src="/assets/BGcircle.png" alt="" className="image3" /> */}
          <section id="hero" className=" text-white mt-2 md:mt-10 md:h-full ">
            <div className="mx-auto flex flex-col md:flex-row items-center justify-around md:justify-between hero">
              <motion.div
                variants={fadein("right", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
                className="md:w-1/2 hero_content"
              >
                <motion.h1
                  variants={fadein("right", 0.2)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: true, amount: 0.7 }}
                  className="font-bold my-6 sm:text-3xl text-2xl lg:6xl leading-loose hero_title"
                >
                  Transform your software development landscape with{" "}
                  <span className="text-[--highlight-color]">
                    MDMultiservices.
                  </span>
                  <TypewriterComponent
                    options={{
                      strings: [
                        "Software Development",
                        "Software Solution",
                        "UI/UX",
                        "DevOps Services",
                        "SEO Services",
                        "Social Media Marketing",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </motion.h1>

                <p className="text-base md:text-xl mb-8 hero_text">
                  A reputable consulting company specializing in empowering
                  businesses through top-tier Software Engineer services and
                  holistic development solutions.
                </p>

                <div className="flex  space-x-3 ">
                  <Link
                    to="/contact"
                    className="bg-[--highlight-color] transition btn btn-hover-effect"
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/contact"
                    className="bg-[--highlight-color] transition btn btn-hover-effect"
                  >
                    Request Call
                  </Link>
                </div>
              </motion.div>

              <div className="w-full md:w-5/12 mt-10 md:mt-10">
                <motion.img
                  variants={fadein("left", 0.5)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: true, amount: 0.7 }}
                  src="/assets/heroimg.png"
                  alt="hero"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </section>
          <section className="flex flex-col">
            <h2 className="text-center text-4xl md:text-5xl font-bold mt-10 md:mb-16">
              Our <span className="text-green-400">Services</span>
            </h2>
            <div className="md:flex justify-center xl:flex-col md:gap-10">
              <Card
                direct="right"
                cardData={cardData}
                handleCardClick={handleCardClick}
              />
              <Card
                direct="left"
                cardData={cardData2}
                handleCardClick={handleCardClick}
              />
            </div>
          </section>

          <section className="technologies">
            {/* <div className="technologies_content"> */}
            {/* <h1 className="technologies_title">
              Technologies <span className="highlight">We Use</span>
            </h1> */}
            {/* <div className="technologies_container">
                <motion.img
                  variants={fadein("up", 0.5)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: true, amount: 0.7 }}
                  src="/assets/technology.png"
                  alt="technology"
                />
              </div>
            </div> */}
            <div className="spacer"></div>
          </section>
          {/* <Project /> */}
          <section className="flex flex-col">
            {/* what our client says */}
            <TestimonialsCarousel />
          </section>
        </div>
        {selectedCard && (
          <PopUp
            title={selectedCard.title}
            highlight={selectedCard.highlight}
            img={selectedCard.img}
            description={selectedCard.description}
            onClose={closePopup}
          />
        )}
      </div>
    </>
  );
}

export default LandingPage;
