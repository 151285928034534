import React from "react";
import { motion } from "framer-motion";
import { fadein } from "../varients";

const AboutMe = () => {
  return (
    <div className="w-[100vw] relative overflow-hidden ">
      <div className="absolute inset-0 z-0">
        <img
          src="/assets/BGcircle.png"
          alt=""
          className="w-full h-full object-cover opacity-30 animate-fadeIn"
        />
        <img
          src="/assets/BGcircle.png"
          alt=""
          className="w-full h-full object-cover opacity-20 animate-fadeIn delay-100"
        />
        <img
          src="/assets/BGcircle.png"
          alt=""
          className="w-full h-full object-cover opacity-10 animate-fadeIn delay-200"
        />
      </div>
      <div className="relative flex flex-col items-center py-10 px-4 sm:px-6 lg:px-8 overflow-hidden max-w-7xl m-auto z-10">
        <section className="about text-center mb-10">
          <motion.h1
            variants={fadein("down", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="aboutus_title text-5xl sm:text-6xl lg:text-6xl font-bold mb-6 "
          >
            About <span className="highlight text-blue-500">Us</span>
          </motion.h1>

          <motion.p
            variants={fadein("down", 0.4)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="text-2xl sm:text-1xl mt-4"
          >
            Welcome to <span className="highlight">MDmultiservices</span>, your
            partner in helping businesses succeed in the digital landscape.
          </motion.p>
        </section>

        <div className=" flex flex-col lg:flex-row justify-around items-center w-full">
          <motion.div
            variants={fadein("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="max-w-md lg:mb-10"
          >
            <img
              src="/assets/about.png"
              alt="person communicate with each other"
              className="rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
            />
          </motion.div>
          <motion.div
            variants={fadein("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="w-full text-left py-5 lg:max-w-md text-lg "
          >
            <p className="my-5 text-xl">
              <span className="text-4xl"> D</span>riving Growth Through Digital
              Innovation with <span className="highlight">MDmultiservices</span>
            </p>

            <div>
              <p>
                As a reliable consulting firm, we focus on providing exceptional
                software engineering services and all-encompassing development
                solutions that create enduring value for our clients.
              </p>
            </div>
          </motion.div>
        </div>

        <div className="w-full text-left mt-10">
          <div className="mb-5">
            <h3
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="highlight font-extrabold text-xl mb-1 text-blue-500"
            >
              Our Mission
            </h3>
            <p className="">
              Our mission is simple: to deliver high-quality, impactful digital
              solutions that drive growth and innovation for our clients. We see
              ourselves as partners in your journey, committed to helping you
              leverage technology to achieve your business goals.
            </p>
          </div>

          <div className="mb-5">
            <motion.h3
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="highlight font-extrabold text-xl mb-1 text-blue-500"
            >
              What We Do..?
            </motion.h3>
            <p className=" mb-2">
              At MDMultiservices, we offer a full spectrum of services designed
              to meet the demands of today’s fast-paced digital landscape:
            </p>
            <ul className="pl-4 md:pl-10 my-5 leading-9">
              {[
                "Full-Stack Development – Transforming ideas into functional, high-quality applications.",
                "UI/UX Design – Crafting intuitive, visually engaging user experiences that keep customers coming back.",
                "DevOps Services – Enabling efficient, agile workflows to accelerate development and deployment.",
                "Digital Marketing & SEO – Boosting your online presence and visibility to reach your target audience effectively.",
                "Social Media Marketing – Building a powerful brand identity and fostering customer engagement.",
              ].map((item, index) => (
                <li key={index} className="list-disc leading-8 md:leading-10 ">
                  <span className="highlight font-bold">
                    {item.split(" – ")[0]}
                  </span>{" "}
                  {item.split(" – ")[1]}
                </li>
              ))}
            </ul>
          </div>

          <div className="mb-5">
            <motion.h3
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="highlight font-extrabold text-xl mb-1 "
            >
              Why Choose MDMultiservices?
            </motion.h3>
            <p className=" ">
              Our reputation is built on a foundation of trust, excellence, and
              a relentless drive for innovation. We approach each project as a
              unique opportunity to make an impact, working closely with our
              clients to understand their objectives and deliver solutions that
              not only meet but exceed expectations.
            </p>
          </div>

          <div className="mb-5">
            <motion.h3
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="highlight font-extrabold text-xl mb-1 text-blue-500"
            >
              Our Path Forward
            </motion.h3>
            <p className=" ">
              As we grow, our vision remains clear: to be a leader in digital
              transformation, driving success for our clients through
              innovation, collaboration, and a passion for excellence. At
              MDMultiservices, every milestone achieved fuels our commitment to
              continuous improvement and long-term partnerships.
            </p>
          </div>

          <div className="mb-5">
            <motion.h3
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="highlight font-extrabold text-xl mb-1 text-blue-500"
            >
              Let’s Build Success Together
            </motion.h3>
            <p className=" ">
              If you're ready to take your business to the next level, we're
              here to help. Contact us today, and let’s discuss how
              MDMultiservices can support your journey to success.
            </p>
          </div>

          <div className="mb-5">
            <motion.h3
              variants={fadein("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="highlight font-extrabold text-xl mb-1 text-blue-500"
            >
              Customer Service and Support
            </motion.h3>
            <p className=" ">
              At MDMultiservices, we’re dedicated to supporting our clients at
              every stage. From planning and development to post-project
              support, we ensure seamless communication, swift responses, and
              tailored assistance to meet unique needs. Our goal is to be more
              than a service provider—we aim to be a reliable partner that
              clients can depend on, even in the face of the unexpected.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
