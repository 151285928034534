import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./component/Navbar"; // Assuming Navbar is in components folder
// import Services from "./pages/services/";
import Services from "./component/Services";
import Career from "./component/Career";
import ContactUs from "./component/ContactUs";
import AboutMe from "./component/AboutMe";
import Footer from "./component/Footer";
import LandingPage from "./LandingPage";
import Maintaince from "./pages/Maintaince";
import NotFound from "./pages/NotFound/page";
import ApplyPage from "./pages/ApplyPage/ApplyPage";
import PrivacyAndTerms from "./pages/Privacy/page";
import CircleTracker from "./component/CircleTracker";

function App() {

  const maintaince = false;


  return maintaince ? (
    <>
      <Maintaince />
      <CircleTracker />
    </>
  ) : (
    // <Router>
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/career/:jobId" element={<ApplyPage />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/privacy" element={<PrivacyAndTerms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
    // </Router>
  );
}

export default App;
