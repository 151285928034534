// src/pages/PrivacyAndTerms.js
import React from "react";

const PrivacyAndTerms = () => {
  return (
    <div className=" p-6 md:p-12">
      {/* Privacy Policy Section */}
      <section className="mb-12">
        <h1 className="text-3xl md:text-4xl font-bold  mb-4">Privacy Policy</h1>
        <p className="mb-6">
          At MD MultiServices, we are committed to protecting your privacy. This
          Privacy Policy outlines how we collect, use, and safeguard your
          information when you use our website and services.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          1. Information We Collect
        </h2>
        <p className="mb-4">
          We collect personal information you provide directly to us, such as
          your name, email address, phone number, and any other details you
          share when contacting us or filling out forms on our website.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          2. How We Use Your Information
        </h2>
        <p className="mb-4">
          The information we collect is used to provide you with the services
          you request, communicate with you, and improve our website. We do not
          sell, trade, or rent your information to third parties.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          3. Data Security
        </h2>
        <p className="mb-4">
          We implement security measures to protect your personal information
          from unauthorized access, disclosure, alteration, or destruction.
          However, no data transmission over the internet can be guaranteed as
          completely secure.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          4. Changes to Our Privacy Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. Changes will be
          posted on this page with an updated effective date.
        </p>
      </section>

      {/* Terms & Conditions Section */}
      {/* <section>
        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Terms & Conditions
        </h1>
        <p className="mb-6">
          By accessing and using the MD MultiServices website, you agree to
          comply with and be bound by the following terms and conditions. Please
          review them carefully.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          1. Use of Our Services
        </h2>
        <p className="mb-4">
          You agree to use our services only for lawful purposes and in a way
          that does not infringe the rights of, restrict, or inhibit anyone
          else’s use of the website.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          2. Intellectual Property Rights
        </h2>
        <p className="mb-4">
          All content, trademarks, and data on this website, including but not
          limited to software, databases, text, graphics, icons, and hyperlinks,
          are the property of MD MultiServices or our content suppliers and are
          protected by intellectual property laws.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          3. Limitation of Liability
        </h2>
        <p className="mb-4">
          MD MultiServices will not be liable for any damages of any kind
          arising from the use of this website, including but not limited to
          direct, indirect, incidental, punitive, and consequential damages.
        </p>

        <h2 className="text-xl md:text-2xl font-semibold mt-6 mb-2">
          4. Governing Law
        </h2>
        <p className="mb-4">
          These Terms and Conditions are governed by the laws of [Your
          Jurisdiction], without regard to its conflict of laws principles.
        </p>
      </section> */}
    </div>
  );
};

export default PrivacyAndTerms;
