import React from "react";
import { useIsSsr } from "../../Hooks/useIsSsr";
function BackToTop() {
  const isSsr = useIsSsr();

  if (isSsr) return null;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div onClick={scrollToTop}>
        <img src="/assets/Up arrow.png" alt="upArrow" />{" "}
      </div>
    </div>
  );
}

export default BackToTop;
