import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold  mb-4">404 - Not Found</h1>
      <p className="text-lg text-green-700 mb-8">
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" className="btn no-underline h-[40px]">
        Go to Home
      </Link>
    </div>
  );
}

export default NotFound;
