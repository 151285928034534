import React from "react";
import { motion } from "framer-motion";
import { fadein } from "../varients";
import { Link } from "react-router-dom";

import "./Footer.css";
// import upArrow from "../assets/Up arrow.png";
import BackToTop from "./BackToTop/BackToTop";

const Footer = () => {
  return (
    <>
      <motion.section
        variants={fadein("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.7 }}
        className="footer "
      >
        {" "}
        {/*fixed bottom-0 left-0 w-full z-50*/}
        <motion.div
          variants={fadein("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="footer_contaier"
        >
          <div className="img">
            <img
              className="footer_logo"
              src="/assets/logo.png"
              alt="
            #1"
            />
          </div>
          <div className="address_container">
            <div>
              <h2>
                <p>Kharadi Pune,Maharashtra</p>
              </h2>
              <h2>
                <p>444405 </p>
              </h2>
            </div>
            <div className="footer_contact">
              <p>
                <i
                  className="fa fa-phone"
                  style={{
                    fontSize: "16px",
                    paddingRight: "10px",
                  }}
                ></i>
                9021461356
              </p>
              <Link to="mailto:info.mdmultiservices@gmail.com">
                info.mdmultiservices@gmail.com
              </Link>
            </div>
          </div>
          <div className="footer_navigation">
            <div className="footer_nav">
              <div>
                <p>
                  <Link to="/about">About</Link>{" "}
                </p>
                <p>
                  <Link to="/career">Career</Link>{" "}
                </p>
                <p>
                  <Link to="/services">Services</Link>{" "}
                </p>
                <p>
                  <Link to="/contact">Contact</Link>{" "}
                </p>
                {/* <p>Growers</p> */}
              </div>
              <div>
                <p>
                  <a
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/94832313"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </p>
                <p>
                  <a
                    rel="noreferrer"
                    href="https://www.instagram.com/mdmultiservices.in?igsh=MTRrNXQzbHgzYTY0ZA=="
                    target="_blanl"
                  >
                    Instagram
                  </a>
                </p>
                <p>
                  <a rel="noreferrer" href="#!">
                    Twitter
                  </a>
                </p>
              </div>
            </div>
            <div className="footer_right_reserverd">
              <p>© 2021 MDMultiservices. All rights reserved.</p>
            </div>
          </div>
          {/* <div>
            <a href="#hero">
              {" "}
              <img src="/assets/Up arrow.png" alt="upArrow" />{" "}
            </a>
          </div> */}
          <BackToTop />
        </motion.div>
      </motion.section>
    </>
  );
};

export default Footer;
