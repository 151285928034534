import React from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion"; //motion
import { fadein } from "../varients";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const { REACT_APP_API_URL } = process.env;
  // Initialize useForm from React Hook Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // console.log("errors", useForm());
  // Handle form submission
  const onSubmit = (data) => {
    // Send the form data to the server

    // create a validation for the mobile number
    if (
      data.number.length !== 10 ||
      isNaN(data.number) ||
      data.number[0] === "0"
    ) {
      Swal.fire({
        title: "Error!",
        text: "Mobile number should be 10 digits!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (data.message.length > 100) {
      Swal.fire({
        title: "Error!",
        text: "Message should lest than 100 characters!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    try {
      axios.post(`${REACT_APP_API_URL}/contact`, data).then((res) => {
        console.log("Response from server:", res);
        Swal.fire({
          title: "Success!",
          text: "Message sent successfully!",
          icon: "success",
          confirmButtonText: "Ok",
        });
      });
    } catch (err) {
      console.log(err);

      Swal.fire({
        title: "Error!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    // console.log("Form submitted:", data);

    reset();
  };

  return (
    <>
      <div className="w-[100vw] min-h-[70vh] relative overflow-hidden">
        <img src="/assets/BGcircle.png" alt="" className="image1" />
        <img src="/assets/BGcircle.png" alt="" className="image2" />
        <img src="/assets/BGcircle.png" alt="" className="image3" />
        <div className="flex flex-col items-center py-1 mb-20 px-4 sm:px-6 lg:px-8 relative">
          {/* Heading Image */}
          <motion.img
            variants={fadein("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            src="/assets/contact.png"
            alt="Contact Us"
            className="mb-4 w-32 h-32 sm:w-40 sm:h-40 lg:w-64 lg:h-64 object-contain"
          />

          {/* Heading */}
          <motion.h1
            variants={fadein("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="text-white font-black text-2xl sm:text-3xl md:text-4xl lg:text-3xl mb-8 text-center"
          >
            GET IN <span className="text-green-600">TOUCH</span> WITH US
          </motion.h1>

          {/* Main Container for Form */}
          <motion.form
            variants={fadein("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            onSubmit={handleSubmit(onSubmit)}
            className=" p-8 rounded-md w-full max-w-4xl glass_effect"
          >
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
              {/* Left Side - Name, Email, Mobile */}
              <div className="flex flex-col w-full md:w-1/2 space-y-4">
                <div className="flex flex-col">
                  <label className="text-white">Name</label>
                  <input
                    type="text"
                    {...register("name", { required: "Name is required" })}
                    className="p-3 rounded-md bg-blue-800 text-white glass_effect_inner"
                    placeholder="Enter your name"
                  />
                  {errors.name && (
                    <span className="text-red-600">{errors.name.message}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-white">Email</label>
                  <input
                    type="email"
                    {...register("email", { required: "Email is required" })}
                    className="p-3 rounded-md bg-blue-800 text-white glass_effect_inner"
                    placeholder="Enter your email"
                  />
                  {errors.email && (
                    <span className="text-red-600">{errors.email.message}</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-white">Contact</label>
                  <input
                    type="text"
                    {...register("number", {
                      required: "Mobile number is required",
                    })}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    className="p-3 rounded-md bg-blue-800 text-white glass_effect_inner"
                    placeholder="Enter your mobile number"
                  />
                  {errors.number && (
                    <span className="text-red-600">
                      {errors.number.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Right Side - Message Box and Buttons */}
              <div className="flex flex-col w-full md:w-1/2 space-y-4">
                <div className="flex flex-col h-full">
                  <label className="text-white">Message</label>
                  <textarea
                    {...register("message", {
                      required: "Message is required",
                    })}
                    className="p-3 rounded-md bg-blue-800 text-white h-full resize-none glass_effect_inner"
                    placeholder="Write your message here"
                    rows="8"
                  ></textarea>
                  {errors.message && (
                    <span className="text-red-600">
                      {errors.message.message}
                    </span>
                  )}
                </div>
                {/* accept privacy */}

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    {...register("privacy", {
                      required: "Please accept the privacy policy",
                    })}
                    className="rounded-md bg-blue-800 text-white"
                  />
                  <label className="text-white">
                    I accept the{" "}
                    <Link
                      href="/privacy"
                      className="text-[#00ff00] hover:underline"
                    >
                      Privacy Policy
                    </Link>
                  </label>
                </div>

                {/* Buttons placed below the message box */}
                <div className="flex justify-between space-x-4">
                  <button
                    type="button"
                    onClick={() => reset()} // Clear the form
                    className="bg-blue-800 w-full md:w-auto bg-[--highlight-color] text-white px-6 py-2 rounded-md transition duration-300 ease-in-out transform hover:bg-blue-900 hover:drop-shadow-lg hover:scale-105;"
                  >
                    Clear
                  </button>
                  <button
                    type="submit"
                    className="bg-[--highlight-color] text-white px-6 py-2 rounded-md w-full md:w-auto btn-hover-effect"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>

            {/* Status Message */}
            {/* {isSubmitSuccessful && (
              <div className="mt-4 text-white text-center">
                Message sent successfully!
              </div>
            )} */}
          </motion.form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
